import { useMemo } from 'react'
import styles from './index.module.scss'

type Props = {
  titleJa?: string
  titleEn: string
}

export const SubHeadLine = ({ titleJa, titleEn }: Props) => {
  const jaElement = useMemo(() => {
    return titleJa ? <div className={styles.title_ja}>{titleJa}</div> : undefined
  }, [titleJa])

  return (
    <h3 className={styles.headline}>
      <div className={styles.title_en}>{titleEn}</div>
      {jaElement}
    </h3>
  )
}
