import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'

export function useRemainTime(end: Date) {
  const [remainSeconds, setRemainSeconds] = useState<number>(0)

  // 残り時間を更新する method
  const updateSaleState = useCallback(() => {
    const now = new Date()
    const diff = dayjs(end).diff(now, 'seconds')
    setRemainSeconds(Math.max(0, diff))
  }, [end])

  // 残り時間を update する timer
  useEffect(() => {
    const timer = setInterval(updateSaleState, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [updateSaleState])

  useEffect(() => {
    updateSaleState()
  }, [])

  return {
    remainSeconds,
  }
}
