/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Game } from '@/models/game'
import { grpc } from '@improbable-eng/grpc-web'
import { Hoop } from '@dena/hoop-proto-client/dist/hoop_pb_service'
import { ListGamesRequest, ListGamesResponse, LIST_GAMES_FILTER, LIST_GAMES_FILTERMap } from '@dena/hoop-proto-client/dist/hoop_pb'
import { HoopErrorUnknown } from '@/lib/error'
import { mapGame } from './parser'

export const filterOption = LIST_GAMES_FILTER

export function listGames(token: string, filter?: LIST_GAMES_FILTERMap[keyof LIST_GAMES_FILTERMap], userName?: string): Promise<Game[]> {
  return new Promise((resolve, reject) => {
    const host = process.env.NEXT_PUBLIC_API_HOST
    if (!host) {
      reject(new HoopErrorUnknown('failed get api host'))
      return
    }

    const request = new ListGamesRequest()

    if (filter) {
      request.setFilter(filter)
    }

    if (userName) {
      request.setUserName(userName)
    }

    grpc.unary(Hoop.ListGames, {
      request,
      host,
      metadata: {
        authorization: `bearer ${token}`,
      },
      onEnd: (response) => {
        const { status, message } = response
        if (status === grpc.Code.OK && message) {
          const data = message.toObject() as ListGamesResponse.AsObject
          console.info('ListGames data = ', data)

          const games = data.gamesList.map((item) => mapGame(item)!)
          resolve(games)
        } else {
          reject(new HoopErrorUnknown(`failed ListGamesRequest status = ${status}`))
        }
      },
    })
  })
}
