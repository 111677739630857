import styles from './index.module.scss'
import LineIcon from '@/assets/icons/line.svg'
import { Image } from '@/components/commons/image'

export const EndOfSeason = () => {
  return (
    <div className={styles.container}>
      <p className={styles.text}>
        いつも応援ありがとうございます。
        <br />
        最新情報は公式LINEアカウントにてお届けします。
      </p>
      <a href={process.env.NEXT_PUBLIC_LINE_FRIEND} className={styles.button} target="_blank" rel="noopener noreferrer">
        <LineIcon className={styles.line_icon} width={32} height={32} />
        PICKFIVEを友だち追加
      </a>
      <div className={styles.background}>
        <Image src={require('@/assets/home/no-game-banner.png')} webp={require('@/assets/home/no-game-banner.png?webp')} width={335} height={308} alt={''} />
      </div>
    </div>
  )
}
